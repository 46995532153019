import React, { useEffect, useRef } from 'react';

const YouTubePlayer = ({ videoId }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    const onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('youtube-player', {
        height: '320',
        width: '640',
        videoId: videoId,
        playerVars: {
          autoplay: 1,
          loop: 1,
          playlist: videoId, // Required for looping
          controls: 0, // Hide controls
          rel: 0, // Disable related videos
          modestbranding: 1, // Minimal YouTube branding
          mute: 0, // Mute the video for autoplay
          fs: 0, // Disable fullscreen button
          cc_load_policy: 0, // Disable closed captions
        },
      });
    };

    // Load the YouTube API script
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

    return () => {
      // Cleanup: remove the player when the component unmounts
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [videoId]);

  return (
    <div>
      <div id="youtube-player" style={{maxWidth:"100%"}}></div>
    </div>
  );
};

export default YouTubePlayer;
