import { mergeStyleSets } from "@fluentui/react";
import { ITheme } from "@theme/foundations";

const getStyles = (theme: ITheme) => mergeStyleSets({
    mainWrapper: {
        padding: theme.spacing.s5,
        borderRadius: '16px',
        // backgroundColor: theme.palette.white,
        // boxShadow: '0px 14px 64px rgba(0, 0, 0, 0.15)',
        width: '100%',
    },
    
    linkSection: {
        width: '100%'
    },
    navLink: {
        '@media(max-width: 992px)': {
            display: 'none',
        }
    },
    getStartedBtn: {
        '@media(max-width: 992px)': {
            display: 'none',
        }
    }
});
export default getStyles;