import React, { useState } from 'react';
import { Stack } from '@fluentui/react';
import { Button, ButtonVariants } from '@atoms/index';
import { useFluentTheme } from '@theme/index';


import getStyles from './NavBar.styles';
import InquiryForm from 'atoms/InquiryForm';

export default function NavBar() {
  const theme = useFluentTheme();
  const [showInquiryForm, toggleInquiryFormState] = useState(false);
  const styles = getStyles(theme);

  return (
    <Stack horizontal verticalAlign='center' className={styles.mainWrapper}>
      
      <Stack
        horizontal
        verticalAlign='center'
        horizontalAlign='center'
        className={styles.linkSection}
        tokens={{ childrenGap: theme.spacing.s5 }}
      >
        {/* <Link onClick={() => navigate('/about')} className={styles.navLink}>
          About
        </Link>
        <Link onClick={() => navigate('/pricing')} className={styles.navLink}>
          Pricing
        </Link> */}
        <Button
          variant={ButtonVariants.Secondary}
          onClick={() => {
            console.log('console log called');
            toggleInquiryFormState(true);
          }}
          styles={{
            root: {
              '@media (min-width: 993px)': {
                display: 'none',
              },
            },
          }}
        >
          Contact us
        </Button>
        <Button
          variant={ButtonVariants.Secondary}
          onClick={() => {
            console.log('console log called');
            toggleInquiryFormState(true);
          }}
          styles={{
            root: {
              '@media (max-width: 992px)': {
                display: 'none',
              },
            },
          }}
        >
          Contact us for demo
        </Button>
      </Stack>
      {showInquiryForm && <InquiryForm onDismiss={() => toggleInquiryFormState(false)} />}
    </Stack>
  );
}
